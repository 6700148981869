<template>
  <b-overlay :show="loading">
    <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
      <b-form  @submit.prevent="handleSubmit(createData)" @reset.prevent="reset" >
          <b-row>
          <b-col lg="12" sm="12">
            <ValidationProvider name="Organization Name"  vid="org_id" rules="required|min_value:1">
              <b-form-group
                  class="row"
                  label-cols-sm="4"
                  label-for="org_id"
                  slot-scope="{ valid, errors }"
              >
                  <template v-slot:label>
                    {{$t('globalTrans.organization')}} <span class="text-danger">*</span>
                  </template>
                  <b-form-select
                  plain
                  v-model="traineeEvaluationData.org_id"
                  :options="orgList"
                  id="org_id"
                  :state="errors[0] ? false : (valid ? true : null)"
                  >
                  <template v-slot:first>
                      <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                  </template>
                  </b-form-select>
                  <div class="invalid-feedback">
                  {{ errors[0] }}
                  </div>
              </b-form-group>
            </ValidationProvider>
          </b-col>
          <b-col lg="12" sm="12">
          <ValidationProvider name="Remarks (En)"  vid="remarks" rules="">
              <b-form-group
                  class="row"
                  label-cols-sm="4"
                  label-for="remarks"
                  slot-scope="{ valid, errors }"
              >
                  <template v-slot:label>
                  {{$t('elearning_config.remarks_en')}}
                  </template>
                  <b-form-textarea
                      rows="2"
                      id="remarks"
                      v-model="traineeEvaluationData.remarks"
                      :state="errors[0] ? false : (valid ? true : null)"
                  ></b-form-textarea>
                  <div class="invalid-feedback">
                      {{ errors[0] }}
                  </div>
              </b-form-group>
          </ValidationProvider>
          </b-col>
          <b-col lg="12" sm="12">
          <ValidationProvider name="Remarks (Bn)"  vid="remarks_bn" rules="">
              <b-form-group
                  class="row"
                  label-cols-sm="4"
                  label-for="remarks_bn"
                  slot-scope="{ valid, errors }"
              >
                  <template v-slot:label>
                  {{$t('elearning_config.remarks_bn')}}
                  </template>
                  <b-form-textarea
                      rows="2"
                      id="remarks_bn"
                      v-model="traineeEvaluationData.remarks_bn"
                      :state="errors[0] ? false : (valid ? true : null)"
                  ></b-form-textarea>
                  <div class="invalid-feedback">
                      {{ errors[0] }}
                  </div>
              </b-form-group>
          </ValidationProvider>
          </b-col>
          </b-row>
          <b-row v-for="(detail, index) in traineeEvaluationData.trainee_evaluation_details" :key="index" class="mt-3">
          <b-col lg="5" md="5" sm="8" xs="8">
              <ValidationProvider name="Trainee Evaluation Name (En)" vid="trainee_evaluation_name" rules="required">
                <b-form-group
                    class="row"
                    label-cols-sm="12"
                    label-for="trainee_evaluation_name"
                    slot-scope="{ valid, errors }"
                >
                <template v-slot:label>
                    {{$t('elearning_config.trainee_evaluation_name_en')}} <span class="text-danger">*</span>
                    </template>
                    <b-form-input
                    id="trainee_evaluation_name"
                    v-model="detail.trainee_evaluation_name"
                    :state="errors[0] ? false : (valid ? true : null)"
                    ></b-form-input>
                    <div class="invalid-feedback">
                    {{ errors[0] }}
                    </div>
                </b-form-group>
              </ValidationProvider>
          </b-col>
          <b-col lg="5" md="5" sm="8" xs="8">
              <ValidationProvider name="Trainee Evaluation Name (Bn)" vid="trainee_evaluation_name_bn" rules="required">
                <b-form-group
                    class="row"
                    label-cols-sm="12"
                    label-for="trainee_evaluation_name_bn"
                    slot-scope="{ valid, errors }"
                >
                <template v-slot:label>
                    {{$t('elearning_config.trainee_evaluation_name_bn')}} <span class="text-danger">*</span>
                    </template>
                    <b-form-input
                    id="trainee_evaluation_name_bn"
                    v-model="detail.trainee_evaluation_name_bn"
                    :state="errors[0] ? false : (valid ? true : null)"
                    ></b-form-input>
                    <div class="invalid-feedback">
                    {{ errors[0] }}
                    </div>
                </b-form-group>
              </ValidationProvider>
          </b-col>
          <b-col xl="2" lg="2" sm="4" style="margin-top: 36px;">
              <b-button v-show="index == traineeEvaluationData.trainee_evaluation_details.length-1" variant="success" size="sm" @click="addItem()"><i class="ri-add-line m-0"></i>{{ $t('globalTrans.add') }}</b-button>
              <b-button v-show="index || ( !index && traineeEvaluationData.trainee_evaluation_details.length > 1)" variant="danger" size="sm" @click="remove(index)"><i class="ri-delete-bin-line m-0"></i></b-button>
          </b-col>
          </b-row>
          <b-row>
          <div class="col text-right mt-3">
              <b-button type="submit" variant="success" class="mr-2 btn-sm">{{ saveBtnName }}</b-button>
              <b-button variant="danger" class="btn-sm" @click="$bvModal.hide('modal-4')">{{ $t('globalTrans.cancel') }}</b-button>
          </div>
          </b-row>
      </b-form>
    </ValidationObserver>
  </b-overlay>
</template>
<script>

import { ValidationObserver, ValidationProvider } from 'vee-validate'
import RestApi, { trainingElearningServiceBaseUrl } from '@/config/api_config'
import { traineeEvaluationStore, traineeEvaluationUpdate } from '../../api/routes'

export default {
  props: ['id'],
  components: {
    ValidationObserver,
    ValidationProvider
  },
  created () {
    if (this.id) {
      const tmp = this.getTraineeEvaluationData()
      this.traineeEvaluationData = tmp
    }
  },
  mounted () {
  },
  data () {
    return {
      loading: false,
      saveBtnName: this.id ? this.$t('globalTrans.update') : this.$t('globalTrans.save'),
      traineeEvaluationData: {
        id: '',
        org_id: 0,
        trainee_evaluation_details: [
          {
            // trainee_evaluation_id: 0,
            trainee_evaluation_name: '',
            trainee_evaluation_name_bn: ''
            // remarks_bn: 'test',
            // remarks: ''
          }
        ],
        remarks: '',
        remarks_bn: '',
        status: 1
      },
      trainingCategory: [],
      trainingTitleList: []
    }
  },
  computed: {
    orgList: function () {
      return this.$store.state.CommonService.commonObj.componentOrgList.filter(item => item.status === 1)
    }
  },
  watch: {
  },
  methods: {
    addItem () {
      const obj = {
        //   trainee_evaluation_id: 0,
          trainee_evaluation_name: '',
          trainee_evaluation_name_bn: ''
        //   remarks_bn: '',
        //   remarks: ''
      }
      const key1 = parseInt(this.traineeEvaluationData.trainee_evaluation_details.length - 1)
     const item = this.traineeEvaluationData.trainee_evaluation_details[key1]
      let isEmpty = true
      Object.keys(item).map(key => {
          if (item[key] === '' || item[key] === 0) {
              isEmpty = false
          }
      })
      if (isEmpty === true) {
        this.traineeEvaluationData.trainee_evaluation_details.push(obj)
      }
    },
    remove (key) {
      this.traineeEvaluationData.trainee_evaluation_details.splice(key, 1)
    },
    getTraineeEvaluationData () {
      const tmpData = this.$store.state.list.find(item => item.id === this.id)
      return JSON.parse(JSON.stringify(tmpData))
    },
    async createData () {
      this.loading = true
      this.$store.dispatch('mutateCommonProperties', { loading: true })
      let result = null
      const loadinState = { loading: false, listReload: false }

      if (this.traineeEvaluationData.id) {
        result = await RestApi.putData(trainingElearningServiceBaseUrl, `${traineeEvaluationUpdate}/${this.id}`, this.traineeEvaluationData)
      } else {
        result = await RestApi.postData(trainingElearningServiceBaseUrl, traineeEvaluationStore, this.traineeEvaluationData)
      }

      loadinState.listReload = true

      this.$store.dispatch('mutateCommonProperties', loadinState)

      if (result.success) {
        this.$store.dispatch('TrainingElearning/mutateTrainingElearningCommonProperties', { hasDropdownLoaded: false })
        this.$toast.success({
          title: 'Success',
          message: this.id ? this.$t('globalTrans.update_msg') : this.$t('globalTrans.save_msg'),
          color: '#D6E09B'
        })

        this.$bvModal.hide('modal-4')
      } else {
        this.$refs.form.setErrors(result.errors)
      }
      this.loading = false
    }
  }
}
</script>
